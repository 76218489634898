import React, { useState } from "react";
import { useScrollPosition } from "../hooks/useScrollPosition";
import useResizeObserver from "../hooks/useResizeObserver";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { mainBody, repos, about, skills, films, photography } from "../editable-stuff/config.js";

const Navigation = React.forwardRef((props, ref) => {
  // const { showBlog, FirstName } = config;
  const [isTop, setIsTop] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarMenuRef = React.useRef();
  const navbarDimensions = useResizeObserver(navbarMenuRef);
  const navBottom = navbarDimensions ? navbarDimensions.bottom : 0;
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!navbarDimensions) return;
      currPos.y + ref.current.offsetTop - navbarDimensions.bottom > 5
        ? setIsTop(true)
        : setIsTop(false);
      setScrollPosition(currPos.y);
    },
    [navBottom]
  );

  React.useEffect(() => {
    if (!navbarDimensions) return;
    navBottom - scrollPosition >= ref.current.offsetTop
      ? setIsTop(false)
      : setIsTop(true);
  }, [navBottom, navbarDimensions, ref, scrollPosition]);

  return (
    <Navbar
      ref={navbarMenuRef}
      className={` fixed-top  ${!isTop ? "navbar-white" : "navbar-transparent"
        }`}
      expand="lg"
    >
      <Navbar.Brand className="brand" href={process.env.PUBLIC_URL + "/#home"}>
        {`${mainBody.firstName}`}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler" />
      <Navbar.Collapse id="basic-navbar-nav">

        <Nav className="mr-auto" >

          {repos.show && (
            <Nav.Link
              className="nav-link lead"

              href={process.env.PUBLIC_URL + "/#projects"}
              style={{ color: 'Gold' }}
            >
              <span style={{ color: 'White' }}>
                Projects
              </span>
            </Nav.Link>
          )}
          <Nav.Link
            className="nav-link lead "
            href={about.resume}
            target="_blank"
            rel="noreferrer noopener"
            style={{ color: 'Gold' }}
          >
            Resume
          </Nav.Link>
          {about.show && (
            <Nav.Link
              className="nav-link lead"
              href={process.env.PUBLIC_URL + "/#aboutme"}
              style={{ color: 'Gold' }}
            >
              About
            </Nav.Link>
          )}
          {photography.show && (
            <Nav.Link
              className="nav-link lead"
              href={process.env.PUBLIC_URL + "/#photography"}
              style={{ color: 'Gold' }}
            >
              Photography
            </Nav.Link>
          )}
          {films.show && (
            <Nav.Link
              className="nav-link lead"
              href={process.env.PUBLIC_URL + "/#films"}
              style={{ color: 'Gold' }}
            >
              Films
            </Nav.Link>
          )}

          {skills.show && (
            <Nav.Link
              className="nav-link lead"
              href={process.env.PUBLIC_URL + "/#skills"}
              style={{ color: 'Gold' }}
            >
              Skills
            </Nav.Link>
          )}
          {/* <NavDropdown className="" title="Films" id="basic-nav-dropdown">
          <NavDropdown.Item href="/rem">REM</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown> */}
        </Nav>

      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
