import React from "react";
import Container from "react-bootstrap/Container";
import EmbedVideo from "./EmbedVideo.jsx";
import Paper from "@material-ui/core/Paper";

const Films = React.forwardRef((props, ref) => {
    return (

        <Container className="text-center portfolioTitle">

            <Paper className="videoPaperContainer">

                <div id="films" classname="filmsContainer">
                    <h2 className="display-4 pb-3 text-center">Films</h2>

                    <EmbedVideo embedId="https://www.youtube.com/embed/UL5Jxh5urx4" />
                    <EmbedVideo embedId="https://player.vimeo.com/video/333250866" />
                    <EmbedVideo embedId="https://player.vimeo.com/video/318869753" />
                    <EmbedVideo embedId="https://player.vimeo.com/video/387776966" />
                    <EmbedVideo embedId="https://www.youtube.com/embed/4wWkjEpqEck" />
                    <EmbedVideo embedId="https://www.youtube.com/embed/QdPrNUbBhBA" />

                    <EmbedVideo embedId="https://www.youtube.com/embed/NSazoTZGXbo" />
                    <EmbedVideo embedId="https://www.youtube.com/embed/yo1xLHR6U94" />

                    <EmbedVideo embedId="https://www.youtube.com/embed/rsIHm8EgOvk" />

                    <EmbedVideo embedId="https://www.youtube.com/embed/znyntkr_ODg" />
                    <EmbedVideo embedId="https://www.youtube.com/embed/9VmxxWPfo88" />





                </div>
            </Paper>

        </Container>
    );
});

export default Films;
