import React from "react";
import Typist from "react-typist";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";

const MainBody = React.forwardRef(
  ({ gradient, title, message, icons }, ref) => {
    return (
      <Jumbotron
        fluid
        id="home"
        style={{
          // background: `linear-gradient(136deg,${gradient})`,
          background: `url("lucas-gray.jpg") no-repeat center center`,
          backgroundSize: "cover"
        }}
        className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div id="stars"></div>
        <Container className="text-center" style={{ color: 'Gold' }}>
          {/* <Container className="text-center" > */}
          <h1 ref={ref} className="display-1" >
            <span >{title}</span>

          </h1>
          <Typist className="lead typist" cursor={{ show: false }}>
            {/* <span style={{ backgroundColor: 'Gold', color: 'White' }}> */}
            <div className="roundCorners" style={{ color: 'Black' }}>
              <h3>
                {" "}
                {message}
              </h3>
            </div>
            {/* </span> */}
          </Typist>
          <div className="p-5">
            {icons.map((icon, index) => (
              <a
                key={`social-icon-${index}`}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i className={`fab ${icon.image}  fa-3x socialicons`} />
              </a>
            ))}
          </div>
          <a
            className="btn btn-outline-light btn-lg "
            href="#aboutme"
            role="button"
            aria-label="Learn more about me"
          // style={{ color: 'DeepPink' }}
          >
            More about me
          </a>
        </Container>
      </Jumbotron >
    );
  }
);

export default MainBody;
