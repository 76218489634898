import React from "react";
import Container from "react-bootstrap/Container";
import EmbedVideo from "./EmbedVideo.jsx";
import Paper from "@material-ui/core/Paper";

const DemoReel = React.forwardRef((props, ref) => {
    return (
        
        <Container className="text-center portfolioTitle">
            
            <Paper className="videoPaperContainer">

                <div id="demo" classname="filmsContainer">                
                    <EmbedVideo embedId="https://player.vimeo.com/video/664036415?h=71b07857ab" />
                    
                    
                </div>
            </Paper>
            
        </Container>
    );
});

export default DemoReel;
